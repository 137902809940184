import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog40.jpg'
import styles from './post-grid.module.css'

const MilkThistle = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jaké účinky má ostropestřec? '}
        description={
          'Ostropestřec – nejen pro zdravá játra!“ Často ze všech stran slýcháme, jaké zázračné a jedinečné účinky Ostropestřec má. Je tomu opravdu tak?'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/drinking-regime">
            Jaký je správný pitný režim?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Jaký je správný pitný režim?" date="12.09.23" />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />

              <p className={styles.postText}>
                Dle toho, jaký je náš věk, co za den sníme, jaké je teplo nebo
                podle toho, jakou máme tělesnou aktivitu, odvozujeme,
                <b> kolik tekutin máme denně vypít. </b>Tekutiny přijímáme nejen
                ze skleničky, ale i z ovoce, zeleniny či polévek a dalších
                pokrmů. Denní příjem tekutin se u jednotlivých osob liší, obecně
                je však známo, že bychom mělo přijmout alespoň
                <b> 1,5 - 2 litry tekutin denně. </b>
              </p>

              <p className={styles.postText}>
                Základem pro náš pitný režim by měla být
                <b> hlavně čistá voda, případně neslazený čaj. </b>Mezi tekutiny
                můžeme počítat i kávu, ale berme v potaz, že z 30 ml espressa
                mnoho tekutin nezískáme, proto je dobré kávu zapít
                <b> sklenkou vody. </b>Nejen, že získáme tekutiny, ale i si naše
                chuťové receptory<b> utříbí chuť po kávě. </b>
              </p>
              <p className={styles.postText}>
                Lidské tělo je složeno až ze 75 % vody a s postupujícím věkem
                procento vody klesá, proto bychom měli pitnému režimu věnovat
                velkou pozornost.
              </p>

              <h5 className={styles.postTitle}>Voda, základ života</h5>
              <p className={styles.postText}>
                Jak jsme si už řekli, voda je základ života a nalezneme ji téměř
                všude na naší planetě. Musíme ji, stejně jako potravu,
                pravidelně doplňovat a přijímat ji v dostatečném množství. V
                našem těle má několik životně důležitých funkcí.
              </p>

              <p className={styles.postText}>
                Můžeme zvolit hned několik způsobů, jak vodu doplňovat.
                Nejčastější verzí bude pravděpodobně obyčejná
                <b> kohoutková voda. </b>Někteří z vás možná mají s kohoutkovou
                vodou problém, a to například kvůli obavám z kontaminace
                mikroorganismů. Jiní si s obyčejnou chutí nevystačí a volí
                raději<b> vody balené. </b>Mikroorganismů se však nemusíte bát
                ani ve vodě kohoutkové, ani v té balené.
              </p>

              <p className={styles.postText}>
                Podívejme se ještě na minerální a perlivé vody. Ty vám mohou
                pomoci pitný režim náležitě<b> zpestřit. </b>V případě, že
                necítíte, že vám tyto vody způsobují nadýmání, nebo vám po nich
                není dobře. Minerální vody obsahují zčásti jiný poměr
                minerálních látek, proto je dobré je<b> obměňovat. </b>Neměly by
                však nahradit čistou vodu úplně.
              </p>

              <p className={styles.postText}>
                Pokud vám voda ve vaší domácnosti nestačí, můžete si ji nějakým
                způsobem zpestřit, například
                <b>
                  {' '}
                  nakrájeným ovocem, limetkovou nebo citronovou šťávou či mátou.{' '}
                </b>
              </p>
              <p className={styles.postText}>
                Jestli máte s dodržováním pitného režimu problém,
                <b> zaveďte určité rutiny</b>, které vám pomohou. Dejte si
                například skleničku vody<b> hned po probuzení. </b>Můžete si
                také koupit<b> speciální lahev</b>, kterou budete mít stále při sobě a
                doplňovat ji.
              </p>
              <h5 className={styles.postTitle}>
                Můžeme zahrnout čaj a kávu do našeho příjmu tekutin?
              </h5>
              <p className={styles.postText}>
                Možná se ráno rozhodujete, co si k snídani dát,
                <b> kávu či čaj</b>? Měli bychom si tedy ujasnit, zdali se jedná
                o<b> zdravou volbu k dočerpání tekutin. </b>
              </p>
              <p className={styles.postText}>
                Opět se jedná o<b> zpestření pitného režimu </b>a konzumace
                těchto tekutin by neměla být vyšší než tolik, kolik pijeme vody.
                Bát se jich rozhodně nemusíme, naopak. V určité míře má jak pití
                kávy, tak čaje, své<b> benefity. </b>Jak čaj, tak káva obsahují
                <b> kofein</b>, který má<b> povzbudivé účinky</b>. To je
                koneckonců často<b> hlavním důvodem </b>pití těchto nápojů.
              </p>
              <p className={styles.postText}>
                Čajový list obsahuje větší množství kofeinu, nežli má kávové
                zrno. Když se ale podíváme blíže, tak zjistíme, že kávových zrn
                na kávu se rozemele daleko více, nežli použijeme lístků čaje k
                přípravě šálku. Tedy v jednom šálku kávy poté vypijeme více
                kofeinu než v šálku čaje. Pokud tedy chcete
                <b> hlavně doplnit energii, můžete sáhnout po kávě. </b> {' '}Jestli
                chcete<b> spíše doplnit tekutiny, zvolte neslazený čaj. </b>
              </p>
              <p className={styles.postText}>
                Slazení kávy či čaje<b> zvyšuje denní příjem kalorií</b>, což je
                v tomhle případě<b> úplně zbytečné. </b>Pokud sladíte, snažte se
                cukr alespoň omezit a časem si na něj úplně odvyknout. Ušetří
                tak spoustu kalorií, které můžete čerpat z mnohem lepších
                zdrojů.
              </p>
              <p className={styles.postText}>
                Ještě je dobré podotknout, že káva má mírně diuretické účinky
                (způsobující zvýšenou tvorbu moči), studie však naznačují, že
                pití kávy významně nepřispívá k dehydrataci. Tedy i když
                <b>
                  {' '}
                  káva může stimulovat močení, nadále přináší do těla tekutinu.
                </b>
              </p>
              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                <b>Základem </b>našeho denního příjmu tekutin by měla být
                <b> čistá voda, </b>ať už<b> balená, </b>nebo
                <b> z kohoutku. </b>Dobrým zdrojem tekutin je také
                <b> neslazený čaj. </b>Pokud máte rádi neslazené
                <b> minerálky nebo perlivou vodu, </b>také se nemusíte bát je do
                svého příjmu tekutin zahrnout. Můžete počítat i kávu, ačkoliv ji
                obvykle pijeme za jiným účelem, než je doplnění tekutin. S kávou
                je také dobré vždy vypít i sklenici vody. Nepodceňujte pitný
                režim a snažte se ho dodržovat, je pro nás velmi důležitý.
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default MilkThistle
